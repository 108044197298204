import React,{useState,useEffect}  from 'react'
import { officeTeamStructurePage, officeTeamWithImage } from '../../service/apiService';
import { config } from '../../config/rest';

export const TeamStructure = () => {
    const [teamImage,setTeamImage]=useState([]);
    const [marketDepart,setmarketDepart]=useState([]);
    const [custservDepart,setCustservDepart]=useState([]);
    const [subAgent,setsubAgent]=useState([]);
    const [branchOffice,setbranchOffice]=useState([]);
    const [marketSupport,setmarketSupport]=useState([]);
    const [subagent,setsubagent]=useState([]);

    const [motordept,setmotordept]=useState([]);
    const [nonmotordept,setnonmotordept]=useState([]);
    const [reinsdept,setreinsdept]=useState([]);
    const [healthdept,sethealthdept]=useState([]);
    const [clmotordept,setclmotordept]=useState([]);
    const [clnmotordept,setclnmotordept]=useState([]);
    const [clmhrdept,setclmhrdept]=useState([]);
    const [clmcomdept,setclmcomdept]=useState([]);
    const [clmprdept,setclmprdept]=useState([]);
    const [clmitdept,setclmitdept]=useState([]);

    useEffect(() => {
      getourTeam();
      getourMarketing();
      getBranchOfice();
      getMarketsupport();
      getSubAgent();
    },[]);

    const getourTeam=async()=>{
        const officeTeam=await officeTeamWithImage();
        setTeamImage(officeTeam.data.data[0].attributes && officeTeam.data.data[0].attributes.TeamWithImage);
        
      }
      const getourMarketing=async()=>{
        const officeTeam=await officeTeamStructurePage();
       // console.log(officeTeam); 
        setmarketDepart(officeTeam.data.data[0].attributes && officeTeam.data.data[0].attributes.MarketingDepartment);
        setCustservDepart(officeTeam.data.data[0].attributes && officeTeam.data.data[0].attributes.customerservice_department);
      }

      const getBranchOfice=async()=>{
        const officeTeam=await officeTeamStructurePage();
        setbranchOffice(officeTeam.data.data[0].attributes && officeTeam.data.data[0].attributes.BranchOffice);
        
      } 

      const getMarketsupport=async()=>{
        const officeTeam=await officeTeamStructurePage()
        setmarketSupport(officeTeam.data.data[0].attributes && officeTeam.data.data[0].attributes.MarketingSupportTeam);
        
      }
     
      const getSubAgent=async()=>{
        const officeTeam=await officeTeamStructurePage()
       // console.log(officeTeam);
        setsubagent(officeTeam.data.data[0].attributes && officeTeam.data.data[0].attributes.subagent);
        
        setmotordept(officeTeam.data.data[0].attributes && officeTeam.data.data[0].attributes.MotorDepartment);
        setnonmotordept(officeTeam.data.data[0].attributes && officeTeam.data.data[0].attributes.NonMotorDepartment
        );
        setreinsdept(officeTeam.data.data[0].attributes && officeTeam.data.data[0].attributes.NonMotorDepartment
        );
        sethealthdept(officeTeam.data.data[0].attributes && officeTeam.data.data[0].attributes.HealthDepartment);
        setclmotordept(officeTeam.data.data[0].attributes && officeTeam.data.data[0].attributes.ClaimMotorDepartment);
        setclnmotordept(officeTeam.data.data[0].attributes && officeTeam.data.data[0].attributes.ClaimNonMotorDepartment);
        setclmhrdept(officeTeam.data.data[0].attributes && officeTeam.data.data[0].attributes.claimHumanResourcesDepartment);
        setclmcomdept(officeTeam.data.data[0].attributes && officeTeam.data.data[0].attributes.ClaimComplianceDepartment);
        setclmprdept(officeTeam.data.data[0].attributes && officeTeam.data.data[0].attributes.ClaimPublicRelationDepartment);
       setclmitdept(officeTeam.data.data[0].attributes && officeTeam.data.data[0].attributes.ClaimInformationTechnologyDepartment);
      }
  return (
    <section>
    <div className="container">
        <div className="section-heading text-center mb-2-9 mb-lg-6 wow fadeIn" data-wow-delay="100ms">
            <span>Our Team</span>
            <h2 className="h1 mb-0">Meet our team member</h2>
        </div>
        <div className="row g-lg-5 mt-n2-9 text-center mb-1-9">
        {teamImage.map(function(data,index) {
            //config.IMG_URL+
  return(
    index==2 ?

        <>
        <div className="col-md-6 col-lg-4 mt-2-9 wow fadeIn" data-wow-delay="200ms" key={index}>
                <div className="team-style1">
                    <div className="image-hover">
                        <img src={config.IMG_URL+data.image.data.attributes.url} 
                         className="rounded" alt="..." 
                        style={{height:'300px'}}/>
                    </div>
                    <div className="team-content">
                        <p className="mb-1">{data.designation}</p>
                        <h3 className="h4 mb-0">{data.name}</h3>
                        <h3 className="h4 mb-0">{data.companyname}</h3>
                        <div className="team-social-icons">
                            <div className="team-share"><i className="fas fa-share-alt"></i></div>
                            <div className="team-social-links">
                                <ul className="m-0 p-0 mt-1">
                                    <li><a href="#!"><i className="ti-facebook"></i></a></li>
                                    <li><a href="#!"><i className="ti-twitter-alt"></i></a></li>
                                    <li><a href="#!"><i className="ti-linkedin"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-6 col-lg-4 mt-2-9"></div>
            
            </>
            :
            <>
             <div className="col-md-6 col-lg-4 mt-2-9 wow fadeIn" data-wow-delay="200ms" key={index}>
                    <div className="team-style1">
                        <div className="image-hover">
                            <img src={config.IMG_URL+data.image.data.attributes.url}  className="rounded" alt="..." 
                            style={{height:'300px'}}/>
                        </div>
                        <div className="team-content">
                            <p className="mb-1">{data.designation}</p>
                            <h3 className="h4 mb-0">{data.name}</h3>
                            <h3 className="h4 mb-0">{data.companyname}</h3>
                            <div className="team-social-icons">
                                <div className="team-share"><i className="fas fa-share-alt"></i></div>
                                <div className="team-social-links">
                                    <ul className="m-0 p-0 mt-1">
                                        <li><a href="#!"><i className="ti-facebook"></i></a></li>
                                        <li><a href="#!"><i className="ti-twitter-alt"></i></a></li>
                                        <li><a href="#!"><i className="ti-linkedin"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4"></div>
                </>
  )})}

           

           

           
            </div>
                <br/><br/>
                
            <div className="row about-style mb-2-9 mb-lg-6">
                <div classNameName="col-lg-12 wow fadeIn table-responsive" data-wow-delay="400ms">
                <table 
                className='table mb-2-9'>
<tbody>
<tr><td colspan="3" height="25"><b>MARKETING DEPARTMENT:</b></td></tr>

{marketDepart.map(function(data,index) {
    return(
<tr key={index}>
<td width="158">{data.designation}</td>
<td width="187">{data.name}</td>
<td width="349">{data.phoneno}</td>
</tr>
    )})}
</tbody>
</table>
                  </div>


                  <div classNameName="col-lg-12 wow fadeIn table-responsive" data-wow-delay="400ms">
                <table 
                className='table mb-2-9'>
<tbody>
<tr><td colspan="3" height="25"><b>CUSTOMER SERVICE DEPARTMENT:</b></td></tr>

{custservDepart.map(function(data,index) {
    return(
<tr key={index}>
<td width="158">{data.Designation}</td>
<td width="187">{data.Name}</td>
<td width="349">{data.phonono}</td>
</tr>
    )})}
</tbody>
</table>
                  </div>



                  <div classNameName="col-lg-12 wow fadeIn table-responsive" data-wow-delay="400ms">
                <table 
                className='table mb-2-9'>
<tbody>
<tr><td colspan="3" height="25"><b>UNDERWRITING DEPARTMENT:</b></td></tr>

<tr><td colspan="3" height="25"><b>Motor Department</b></td></tr>
{motordept.map(function(data,index) {
    return(
<tr key={index}>
<td width="158">{data.Designation}</td>
<td width="187">{data.Name}</td>
<td width="349">{data.phoneno}</td>
</tr>
    )})}
</tbody>
</table>

<table 
                className='table mb-2-9'>
<tbody>
<tr><td colspan="3" height="25"><b>Non – Motor Department</b></td></tr>
{nonmotordept.map(function(data,index) {
    return(
<tr key={index}>
<td width="158">{data.Designation}</td>
<td width="187">{data.Name}</td>
<td width="349">{data.phoneno}</td>
</tr>
    )})}
</tbody>
</table>

<table 
                className='table mb-2-9'>
<tbody>
<tr><td colspan="3" height="25"><b>Re-insurance Department:</b></td></tr>
{reinsdept.map(function(data,index) {
    return(
<tr key={index}>
<td width="158">{data.Designation}</td>
<td width="187">{data.Name}</td>
<td width="349">{data.phoneno}</td>
</tr>
    )})}
</tbody>
</table>

<table 
                className='table mb-2-9'>
<tbody>
<tr><td colspan="3" height="25"><b>Health Department:</b></td></tr>
{healthdept.map(function(data,index) {
    return(
<tr key={index}>
<td width="158">{data.Designation}</td>
<td width="187">{data.Name}</td>
<td width="349">{data.phoneno}</td>
</tr>
    )})}
</tbody>
</table>


<table 
                className='table mb-2-9'>
<tbody>
<tr><td colspan="3" height="25"><b>CLAIM DEPARTMENT:</b></td></tr>
<tr><td colspan="3" height="25"><b>Motor Department:</b></td></tr>
{clmotordept.map(function(data,index) {
    return(
<tr key={index}>
<td width="158">{data.Designation}</td>
<td width="187">{data.Name}</td>
<td width="349">{data.phoneno}</td>
</tr>
    )})}
</tbody>
</table>

<table 
                className='table mb-2-9'>
<tbody>
<tr><td colspan="3" height="25"><b>Non-Motor Department:</b></td></tr>
{clnmotordept.map(function(data,index) {
    return(
<tr key={index}>
<td width="158">{data.Designation}</td>
<td width="187">{data.Name}</td>
<td width="349">{data.phoneno}</td>
</tr>
    )})}
</tbody>
</table>

<table 
                className='table mb-2-9'>
<tbody>
<tr><td colspan="3" height="25"><b>Human Resources Department:</b></td></tr>
{clmhrdept.map(function(data,index) {
    return(
<tr key={index}>
<td width="158">{data.Designation}</td>
<td width="187">{data.Name}</td>
<td width="349">{data.phoneno}</td>
</tr>
    )})}
</tbody>
</table>

<table 
                className='table mb-2-9'>
<tbody>
<tr><td colspan="3" height="25"><b>Compliance Department:</b></td></tr>
{clmcomdept.map(function(data,index) {
    return(
<tr key={index}>
<td width="158">{data.Designation}</td>
<td width="187">{data.Name}</td>
<td width="349">{data.phoneno}</td>
</tr>
    )})}
</tbody>
</table>

<table 
                className='table mb-2-9'>
<tbody>
<tr><td colspan="3" height="25"><b>Public Relation Department:</b></td></tr>
{clmprdept.map(function(data,index) {
    return(
<tr key={index}>
<td width="158">{data.Designation}</td>
<td width="187">{data.Name}</td>
<td width="349">{data.phoneno}</td>
</tr>
    )})}
</tbody>
</table>

<table 
                className='table mb-2-9'>
<tbody>
<tr><td colspan="3" height="25"><b>Information & Technology Department:</b></td></tr>
{clmitdept.map(function(data,index) {
    return(
<tr key={index}>
<td width="158">{data.Designation}</td>
<td width="187">{data.Name}</td>
<td width="349">{data.phoneno}</td>
</tr>
    )})}
</tbody>
</table>

                  </div>


                  <div className="inner-title mb-2-9"><h2 className="h4 mb-0">BRANCH OFFICES:</h2></div>
  <div classNameName="col-lg-12 wow fadeIn table-responsive" data-wow-delay="400ms">
  <table className='table mb-2-9'>
  <tbody>
    <tr>
        <td width="9%">Location</td>
        <td width="25%">Officer – In – Charge</td>
        <td width="15%">Post</td><td width="23%">Off: Phone</td>
        <td width="11%">GSM</td>
 </tr>
 {branchOffice.map(function(data,index) {
    return(
        <tr key={index}>
            <td >{data.location}</td>
            <td>{data.OfficeinCharge}</td>
            <td>{data.post}</td>
            <td>{data.phone}</td>
            <td>{data.gsm}</td>
        </tr>
    )})}
</tbody>
</table>
</div>

<div className="inner-title"><h2 className="h4 mb-2-9">Marketing Supporting Team:</h2></div>
  <div classNameName="col-lg-12 wow fadeIn table-responsive" data-wow-delay="400ms">
  <table className='table mb-2-9'>
  <tbody>
  {marketSupport.map(function(data,index) {
    return(
<tr key={index}>
<td width="158">{data.designation}</td>
<td width="187">{data.name}</td>
<td width="349">{data.phoneno}</td>
</tr>
    )})}
</tbody>
</table>
</div>


<div className="inner-title"><h2 className="h4 mb-2-9">SUB AGENTS:</h2></div>
  <div classNameName="col-lg-12 wow fadeIn table-responsive" data-wow-delay="400ms">
  <table className='table mb-2-9'>
<tbody>
    <tr>
        <td>SUB AGENT NAME</td>
        <td>LOCATION</td>
        <td>CONTACT NO.</td>
        </tr>
        <tbody>
  
</tbody>
{subagent.map(function(data,index) {
    return(
<tr key={index}>
<td width="187">{data.name}</td>
<td width="158">{data.Location}</td>
<td width="349">{data.phoneno}</td>
</tr>
    )})}  
        </tbody></table>
        </div>
  </div>
  


            </div>
            
            </section>
  )
}
