import React from 'react'

export const HomeAddress = (props) => {
  return (
    <div className="overflow-visible">
    <div className="container">
        <div className="row footer-address">
            <div className="col-lg-3 mb-3 mb-lg-0">
                <div className="bg-white p-1-9 shadow border-radius-10 text-center h-100">
                    <div className="footer-logo mx-auto">
                        <img src="/assets/img/logos/logo.png" alt="..."/>
                    </div>
                </div>
            </div>
            {props.address.map(function(data,index) {
            return(
            <div className="col-lg-9" key={index}>
                <div className="bg-white p-1-9 shadow border-radius-10 position-relative word-wrap">
                    <div className="row mt-n3">
                        <div className="col-md-4 mt-3">
                            <div className="d-flex">
                                <div className="flex-shrink-0">
                                    <i className="ti-location-pin display-24 mt-1 d-block text-primary"></i>
                                </div>
                                <div className="flex-grow-1 ms-3">
                                    <p className="mb-0 font14" dangerouslySetInnerHTML={{ __html: data.attributes.address }}/>
                                        
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mt-3">
                            <div className="d-flex">
                                <div className="flex-shrink-0">
                                    <i className="ti-mobile display-24 mt-1 d-block text-primary"></i>
                                </div>
                                <div className="flex-grow-1 ms-3">
                                    <p className="mb-0 font14"> {data.attributes.phOne}</p>
                                    <p className="mb-0 font14"> {data.attributes.phoneTwo}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mt-3">
                            <div className="d-flex">
                                <div className="flex-shrink-0">
                                    <i className="ti-email display-24 mt-1 d-block text-primary"></i>
                                </div>
                                <div className="flex-grow-1 ms-3">
                                    <p className="mb-0 font14">{data.attributes.emailOne}</p>
                                    <p className="mb-0 font14">{data.attributes.emailTwo}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            )})}
        </div>
    </div>
</div>

  )
}
