import React ,{useEffect,useState}from 'react'
import Header from '../Header'
import Footer from '../Footer'
import { useSelector,useDispatch } from "react-redux";
import { addItem } from '../../redux/Action/CartAction';
import { listHomeAbout, listHomeAboutar, listHomecontacts, listHomecontactsar } from '../../service/apiService';


export default function Layout (props) {
 
  const dispatch = useDispatch();
  const incrData=useSelector(state=>state.cart);
  const langData=useSelector(state=>state.cart);
  const [homeContact,sethomeContact]=useState([]);
  const [homeAbt,setHomeAbt]=useState([]); 

    const rootHtml = document.getElementById("root-html");
    if (rootHtml) {
     rootHtml.setAttribute("dir", langData.lang==='ar' ? "rtl" : "ltr");
     rootHtml.setAttribute("lang", langData.lang ? "rtl" : "ltr");
   }


  useEffect(() => {
     //   console.log(incrData); 
     getHomeAdbout();
     getHomeContact();
  },[incrData]);


  const getHomeContact=async()=>{
    const homeContact=langData.lang==='ar'?await listHomecontactsar() :await listHomecontacts();
    sethomeContact(homeContact.data.data);
  }
  
  const getHomeAdbout=async()=>{
    const homeAbt=langData.lang==='ar'?await listHomeAboutar() :await listHomeAbout();
    setHomeAbt(homeAbt.data.data[0]);
  }



  const chnlang=(val)=>{
    console.log(val);
   dispatch(addItem({val}));
   //window.location.reload();
  }
  return (
    <div className="main-wrapper">
    <Header changeLang={(val)=>chnlang(val)} contactData={homeContact[0]}/>
    {props.children}
    <Footer abtData={homeAbt}/>
   
    </div>
  )
}
